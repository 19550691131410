import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Optional } from "@/__main__/data-model.mjs";

const ProfileHeroStats = Mapped({
  key: String,
  value: {
    wins: Number,
    losses: Number,
    games_played: Number,
    kills: Number,
    deaths: Number,
    assists: Number,
    denies: Number,
    last_hits: Number,
    net_worth: Number,
    time_played: Number,
    player_damage: Number,
    player_healing: Number,
    shots_missed: Number,
    shots_hit: Number,
    hero_bullets_hit_crit: Number,
    hero_bullets_hit: Optional(Number), // BE forgot to calculate this, remove Optional when they add it
    first_match_id_processed: Number,
    last_match_id_processed: Number,
  },
}) satisfies ModelDefinition;

export const ProfileHeroStatsValidator = createModel(ProfileHeroStats);

export type ProfileHeroStats = DeepReadonly<
  FromModel<typeof ProfileHeroStatsValidator>
>;

export type ProfileHero = DeepReadonly<ProfileHeroStats["x"]>;
